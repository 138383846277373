<template>
  <div
    class="loginPage"
    v-loading.fullscreen.lock="fullscreenLoading"
    element-loading-text="页面加载中..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="formBox">
      <h1>{{!isGxjt ? '赢商大数据云商管系统' :'商业物业管理平台系统'}}</h1>
      <h2 v-if="!isGxjt">Windata cloud management</h2>
      <el-form
        ref="form"
        :model="form"
        label-width="0"
        :rules="rules"
        status-icon
      >
        <el-form-item
          label=""
          prop="enterpriseCode"
        >
          <el-input
            v-model="form.enterpriseCode"
            placeholder="请输入企业编码"
          >
            <span slot="prefix">
              <!-- 这里UI处理的icon 使用了多色，Unicode和font class的引入方式不支持多色，
              symbol 和 svg引入方式 可正常显示，
              现如今项目使用的是Unicode引入的方式，而symbol引入（svg）的浏览器渲染性能很一般，
              所以暂时多色icon单独svg引入或者png引入-->
              <svg
                style="
                position: relative;
                top: 3px;
                left: 2px;"
                t="1627020888921"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="21842"
                width="16"
                height="16"
              >
                <path
                  d="M512 512m-512 0a512 512 0 1 0 1024 0 512 512 0 1 0-1024 0Z"
                  fill="#DAA661"
                  p-id="21843"
                ></path>
                <path
                  d="M587.392 713.472a217.6 217.6 0 0 0 138.688-192c47.616-24.512 76.8-50.88 71.168-75.008-3.008-12.8-14.784-22.464-35.072-28.416a10.304 10.304 0 1 0-5.824 19.712c12.16 3.52 19.712 8.384 20.864 13.312 5.312 22.912-90.24 80.192-252.16 117.824s-272.96 28.352-278.272 5.44c-1.28-5.504 4.8-14.528 16.256-24.192a10.24 10.24 0 1 0-13.184-15.68c-18.56 15.616-26.304 30.592-23.04 44.8 5.44 23.616 41.6 34.496 93.12 35.904a224.448 224.448 0 0 0 190.208 112.96 215.872 215.872 0 0 0 67.136-10.624"
                  fill="#FFFFFF"
                  p-id="21844"
                ></path>
                <path
                  d="M526.592 551.232a788.48 788.48 0 0 0 203.712-78.4c-26.624-91.456-72.768-176.448-220.16-176.448-151.616 0-215.616 116.352-215.616 235.264a219.776 219.776 0 0 0 3.904 41.024 925.44 925.44 0 0 0 228.16-21.44z"
                  fill="#FFFFFF"
                  p-id="21845"
                ></path>
              </svg>
            </span>
          </el-input>
        </el-form-item>
        <el-form-item
          label=""
          prop="account"
        >
          <el-input
            v-model="form.account"
            placeholder="请输入用户名称或手机号"
          >
            <i
              slot="prefix"
              class="icon-account"
            ></i>
          </el-input>
        </el-form-item>
        <el-form-item
          label=""
          prop="password"
        >
          <el-input
            type="password"
            placeholder="请输入登录密码"
            v-model="form.password"
          >
            <i
              slot="prefix"
              class="icon-password"
            ></i>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="login">登录</el-button>
        </el-form-item>
      </el-form>
      <img
        v-if="!isGxjt"
        src="https://img3.winshang.com/wbms/logo-login.png"
        width="113"
        height="31"
        alt=""
      >
    </div>
    <!-- <div class="footer">
      <ul>
        <li>售后客服微信：win18578675071</li>
        <li><a href="http://www.winshangdata.com/" target="_blank">赢商大数据</a></li>
        <li><a href="http://www.winshang.com/index.html" target="_blank">赢商网</a></li>
        <li><a href="" target="_blank">赢商公众号</a></li>
      </ul>
      Copyright @ 2009-2020 All Rights Reserved 粤ICP备10067299号
    </div> -->
    <Footer></Footer>
  </div>
</template>

<script>
import { Md5 } from '@/utils/md5'
import Footer from '@/components/Footer.vue'
import {
  setCookie,
  // removeCookie,
  browserKernel
} from '@/utils'
import api from '@/api'
export default {
  name: 'loginPage',
  components: { Footer },
  data () {
    const checkEnterpriseCode = (rule, value, callback) => {
      const str = value.replace(/\s*/g, '')
      if (str) {
        callback()
      } else {
        callback(new Error('请输入企业编码'))
      }
    }
    return {
      form: {
        enterpriseCode: '',
        account: '',
        password: ''
      },
      rules: {
        enterpriseCode: [{ validator: checkEnterpriseCode, message: '请输入企业编码' }],
        account: [{ required: true, message: '账户名或手机号不能为空' }],
        password: [{ required: true, message: '密码不能为空' }]
      },
      fullscreenLoading: false,
      originUrl: '',
      urlParams: ''
    }
  },
  computed: {
    temUrl () {
      let temUrl = ''
      if (process.env.NODE_ENV === 'development') {
        temUrl = 'http://' + document.domain + ':8080'
      }
      return temUrl
    }
  },
  mounted () {
    // console.log(navigator.userAgent)
    // console.log(getBrowserInfo())
    // console.log(browserKernel())
    // removeCookie('wisdom_token')
    // removeCookie('wisdom_user')
    if (browserKernel().webKit || browserKernel().gecko) {
      // console.log('是谷歌火狐内核')
    } else {
      // console.log('不是是谷歌火狐内核')
      this.axios.post(api.ipPrompt)
        .then((res) => {
          if (res.data.data) {
            this.$alert('为了更好的浏览体验，建议您使用新版Chrome或Firefox浏览器！', '提示', {
              center: true,
              confirmButtonText: '确定',
              callback: action => {
              }
            })
          }
        })
    }
    document.onkeydown = (event) => {
      const e = event || window.event
      if (e && e.keyCode === 13) {
        this.login()
      }
    }
  },
  methods: {
    isIE () {
      if (!!window.ActiveXObject || 'ActiveXObject' in window) { return true } else { return false }
    },
    login () {
      let time = ''
      this.$refs.form.validate(valid => {
        if (valid) {
          const md5 = new Md5()
          const md5Pwd = md5.hex_md5(this.form.password).toUpperCase()
          const params = {
            pwd: md5Pwd,
            uid: this.form.account
          }
          this.fullscreenLoading = true
          this.axios.post(api.getSysTime, params)
            .then((res) => {
              time = res.data.data
              localStorage.setItem('sysTime', time)
              localStorage.setItem('diffTimeVal', time - new Date().valueOf())
              this.loginIn(time, params)
            })
        }
      })
    },
    loginIn (time, params) {
      params.type = 0
      params.companyCode = this.form.enterpriseCode
      return this.axios({
        method: 'post',
        url: api.userLogin,
        data: params,
        headers: {
          timestamp: time
        }
      })
        .then((res) => {
          if (res.data.code === 0) {
            // 设置uid cookie
            const userid = {
              key: 'wisdom_user',
              value: res.data.data.userid,
              domain: document.domain.split('.').slice(-2).join('.'),
              expireDays: 0
            }
            setCookie(userid)
            const token = {
              key: 'wisdom_token',
              value: res.data.data.token,
              domain: document.domain.split('.').slice(-2).join('.'),
              expireDays: 0
            }
            setCookie(token)
            if (this.isIE()) {
              window.location.href = this.temUrl + '/#/'
              if (document.domain.indexOf('local') !== -1 || document.domain.indexOf('prewbms') !== -1) {
                if (this.isIE()) {
                  window.location.reload(true)
                }
              }
            } else {
              setTimeout(() => {
                if (window.location.href.indexOf('winshangdata.com') > -1) {
                  if (this.$route.hash === '#/') {
                    window.location.href = this.temUrl + '/#/'
                  } else if (this.$route.query.backurl && this.$route.query.backurl.indexOf('localhost') !== -1) {
                    window.location.href = this.$route.query.backurl + '?tokenval=' + token.value
                  } else {
                    window.location.href = this.temUrl + '/#/'
                  }
                } else {
                  if (this.$route.query.backurl && this.$route.query.backurl.indexOf('localhost') !== -1) {
                    window.location.href = this.$route.query.backurl + '?tokenval=' + token.value
                  } else {
                    window.location.href = this.temUrl + '/#/'
                  }
                }
                // if (this.$route.hash === '#/') {
                //   window.location.href = this.temUrl + '/#/'
                // } else
              }, 1000)
            }
          } else {
            this.fullscreenLoading = false
            this.$message({
              message: res.data.msg,
              type: 'error'
            })
          }
        })
        .catch((res) => {
          this.fullscreenLoading = false
          this.$message({
            message: res.data.msg,
            type: 'error'
          })
        })
    }
  }
}
</script>

<style scoped lang="stylus">
.loginPage
  height calc(100vh)
  min-height 730px
  width 100%
  background-color #1E1E23
  overflow hidden
  .formBox
    margin 15vh auto
    box-shadow 0px 19px 46px 0px rgba(2, 5, 19, 0.48)
    width 1180px
    height 476px
    padding-right 710px
    background #fff url('~@/assets/images/login/img.png') right no-repeat
    font-family '微软雅黑'
    text-align center
    overflow hidden
    position relative
    img
      position absolute
      left 495px
      bottom 25px
    h1
      font-size 30px
      font-weight 500
      color #333333
      margin 62px 0 10px 0
    h2
      font-size 16px
      font-weight 400
      color #333333
      opacity 0.8
    .el-form>>>
      margin-top 40px
      .el-form-item
        margin-bottom 12px
      .el-input, .el-button
        width 410px
        height 40px
        margin-bottom 20px
      .el-button
        margin-top 10px
        color #fff !important
        background #FCA000 !important
        box-shadow 0px 9px 24px 0px rgba(252, 160, 0, 0.25)
        border none
        border-radius 4px 4px 4px 4px
        &:hover
          box-shadow 0px 19px 24px 0px rgba(252, 160, 0, 0.25)
  .footer-wrap
    width 100%
    position fixed
    bottom 0
.loginPage>>>
  .el-form-item__error
    left 31px
    padding 0
    top 80%
</style>
